<template>
  <div class="home">
    <h3>Geosearch Critical Minerals</h3>
    <h4>Energy Reimagined | Risk Simplified</h4>
  </div>
</template>

<script>

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home'
}
</script>