<template>
  <v-app>
    <v-app-bar
      collapse
      collapse-on-scroll
      color="light-green"
      dark
      dense
      >
      <div id="nav">
        <router-link to="/"><i>Beta</i></router-link> |
        <router-link to="/about">About</router-link> |
        <router-link to="/why">Why</router-link> |
        <router-link to="/how">Quick Start</router-link>
        <!-- <router-link to="/ads">Sponsor</router-link> -->
      </div>
      <v-spacer></v-spacer>
      <router-view/>
      <div class="d-flex align-center">
        <a href="https://core2coregeologic.com" target="_blank"><v-img
          alt="sponsor"
          class="shrink mr-2"
          src="./assets/core2core.png"
          width="120" 
          style="top: 1px; right: -60px;"
        /></a>
      </div>
      <!-- auto space between HelloWorld and Log in -->
      <v-spacer></v-spacer>
      <!-- drop down opeions for language support v-menu etc. -->
    </v-app-bar>
    <GmapMap
      :center="center"
      :zoom="10.5"
      map-type-id="roadmap"
      style="width: 100%; height: 700px"
      @click="updateCenter"
      >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"        
      />
      <GmapCircle
      :center="center"
      :radius="10000"
      :options="{fillColor:'green',fillOpacity:0.1}"
      />
    </GmapMap>
    <p>Center: <b>Latitude</b> {{ center.lat.toFixed(2) }} , <b>Longitude</b> {{ center.lng.toFixed(2) }} </p>
    <p></p>

    <div id="msg" style="color:Red;">
      {{ message }}
    </div>

    <p> </p>
    <div id="sum" style="color:Green;" v-if = 'markersdata.length > 1'>
      <p>Data source: <a href="https://mrdata.usgs.gov/#mineral-resources" target="_blank"><span>USGS Mineral Resources</span></a></p>
      {{ total }}
    </div>
    <p> </p>
    <div id="appHistas" v-if = 'as.length > 3'>
        <p style="color:#3B444B;">Arsenic (<b>As</b>) concentration (parts-per-million) histogram of {{ as.length - 1 }} samples</p>
        <GChart
          type="Histogram"
          :data="as"
          :options="{ height: 600,
            colors: ['#3B444B'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 10,
            hideBucketItems: 'false'}"
        />
    </div>
    <div id="appHistco" v-if = 'co.length > 3'>
        <p style="color:#0047AB;">Cobalt (<b>Co</b>) concentration (parts-per-million) histogram of {{ co.length - 1 }} samples</p>
        <GChart
          type="Histogram"
          :data="co"
          :options="{ height: 600,
            colors: ['#0047AB'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 10,
            hideBucketItems: 'false'}"
        />
    </div>
    <div id="appHistga" v-if = 'ga.length > 3'>
        <p style="color:#006400;">Gallium (<b>Ga</b>) concentration (parts-per-million) histogram of {{ ga.length - 1 }} samples</p>
        <GChart
          type="Histogram"
          :data="ga"
          :options="{ height: 600,
            colors: ['#006400'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 10,
            hideBucketItems: 'false'}"
        />
    </div>
    <div id="appHistli" v-if = 'li.length > 3'>
        <p style="color:#cc0033;">Lithium (<b>Li</b>) concentration (parts-per-million) histogram of {{ li.length - 1 }} samples</p>
        <GChart
          type="Histogram"
          :data="li"
          :options="{ height: 600,
            colors: ['#cc0033'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 10,
            hideBucketItems: 'false'}"
        />
    </div>
    <div id="appHistmn" v-if = 'mn.length > 3'>
        <p style="color:#e52b50;">Manganese (<b>Mn</b>) concentration (parts-per-million) histogram of {{ mn.length - 1 }} samples</p>
        <GChart
          type="Histogram"
          :data="mn"
          :options="{ height: 600,
            colors: ['#e52b50'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 10,
            hideBucketItems: 'false'}"
        />
    </div>
    <div id="appHistau" v-if = 'au.length > 3'>
        <p style="color:#FFD700;"><b>Gold</b> (<b>Au</b>) concentration (parts-per-million) histogram of {{ au.length - 1 }} samples</p>
        <GChart
          type="Histogram"
          :data="au"
          :options="{ height: 600,
            colors: ['#FFD700'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 10,
            hideBucketItems: 'false'}"
        />
    </div>
    <div id="appHistag" v-if = 'ag.length > 3'>
        <p style="color:#C0C0C0;"><b>Silver</b> (<b>Ag</b>) concentration (parts-per-million) histogram of {{ ag.length - 1 }} samples</p>
        <GChart
          type="Histogram"
          :data="ag"
          :options="{ height: 600,
            colors: ['#C0C0C0'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 10,
            hideBucketItems: 'false'}"
        />
    </div>
    <div id="appHist1" v-if = 'al2o3hist.length > 3'>
        <p style="color:#848789;">Aluminum (<b>Al</b>) concentration (percent) histogram of {{ al2o3hist.length - 1 }} samples</p>
        <GChart
          type="Histogram"
          :data="al2o3hist"
          :options="{ height: 600,
            colors: ['#848789'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 10,
            hideBucketItems: 'false'}"
        />
    </div>
    <div id="appHist2" v-if = 'cuppmhist.length > 3'>
        <p style="color:#b87333;">Copper (<b>Cu</b>) concentration (parts-per-million) histogram of {{ cuppmhist.length - 1 }} samples</p>
        <GChart
            type="Histogram"
            :data="cuppmhist"
            :options="{ height: 600,
            colors: ['#b87333'],
            minValue: 0,
            bucketSize: 0.25,
            maxNumBuckets: 10,
            hideBucketItems: 'false'}"
        />
    </div>
    <div id="xplot" v-if = 'al2o3_cuppm_xplot.length > 3' >
        <GChart
            type="ScatterChart"
            :data="al2o3_cuppm_xplot"
            :options="xplotOptions"
        />
        <p style="color:#D19C4C;"> <b>{{ al2o3_cuppm_xplot.length - 1 }} mineral samples fit</b></p>
    </div>
    <div id="appTable" v-if = 'markersdata.length > 1'>
      <GChart
        :settings="{ packages: ['table'] }"
        type="Table"
        :data="markersdata"                    
        :options="{ height: 600,
          width:  '90%',
          page: 'enable',
          pageSize: 12,
          sortAscending: 'false',
          sortColumn: 1}"
        ref="gChart"
      />
      <p> Concentration reported as parts per million (ppm), or percent (%).</p>
    </div>
    <!--
    <p> </p>
    <div id="appChart" v-if = 'markerschart.length > 1'>
      <GChart
        type="ColumnChart"
        :data="markerschart"
        :options="chartOptions"
      />    
    </div>
    -->
    <v-footer
      color="light-green darken-2"
      padless
      >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          x-small
          color="white"
          text
          disabled
        >
        </v-btn>
        <v-col
          class="light-green darken-2 text-center white--text"
        >
          <small> Copyright &copy; {{  new Date().getFullYear() }} <strong> Shaleforce LLC</strong>. All rights reserved. </small>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

import axios from 'axios';
import { GChart } from "vue-google-charts";

// return true if in range, otherwise false
function inRange(x, min, max) {
    return ((x-min)*(x-max) <= 0);
}
// determine number of wells per formation
/* var occurrence = function (array) {
    "use strict";
    var result = {};
    if (array instanceof Array) {
        array.forEach(function (v, i) {
            if (!result[v]) {
                result[v] = [i];
            } else {
                result[v].push(i);
            }
        });
        Object.keys(result).forEach(function (v) {
            result[v] = result[v].length;
        });
    }
    return result;
}; */

export default {
  name: 'App',
  components: {
      GChart
    },
  data() {
    return {
      // default to nearby ara
      // center: { lat: 46.459, lng: -90.29 },
      center: { lat: 61.35, lng: -153.57 },
      message: '',
      total: '',
      markers: [],
      markersdata: [],
      as: [],
      co: [],
      li: [],
      ga: [],
      mn: [],
      ag: [],
      au: [],
      al2o3hist: [],
      cuppmhist: [],
      al2o3_cuppm_xplot: [],
      // markersfmt: [],
      // markerschart: [],
      places: [],
      currentPlace: null,
      /* tableOptions: {
        chart: {
          title: "Minerals Summary",
          subtitle: "LAB ID, AL and Copper"
        },
        height: 600,
        width:  '90%',
        page: 'enable',
        pageSize: 12,
        sortAscending: 'false',
        sortColumn: 1
      }, */
      /* tableEvents: {
        select: () => {          
          const table = this.$refs.gChart.chartObject;
          const selection = table.getSelection();          
          const onSelectionMeaasge = selection.length !== 0 ? 'row was selected' : 'row was diselected'
          alert(onSelectionMeaasge);
        }
      }, */
      // histogram
      /* al2O3Options: {
        title: "Distribution of AL2O3 concentration (X-ray fluorescence spectrometry)",
        legend: { position: 'none' },
        hAxis: {
          title: 'AL2O3 (percent)',
          titleTextStyle: { color: '#696969', bold: 'True'}
        },
        vAxis:{title: 'Number of Samples'},
        height: 600,
        colors: ['#848789'],
        minValue: 0,
        isStacked: ["True"],
        bar: { gap: 0 },
        histogram: {
          bucketSize: 0.25,
          maxNumBuckets: 10,
          hideBucketItems: 'false'
        }
      }, */
      /* cuppmOptions: {
        title: 'Distribution of Copper concentration (inductively coupled plasma-atomic emission spectrometry)',
        legend: { position: 'none' },
        hAxis: {
          title: 'Copper (parts-per-million)',
          titleTextStyle: { color: '#696969', bold: 'True'}
        },
        vAxis:{title: 'Number of Samples'},
        height: 600,
        colors: ['#b87333'],
        minValue: 0,
        isStacked: ["True"],
        bar: { gap: 0 },
        histogram: {
          bucketSize: 0.25,
          maxNumBuckets: 10,
          hideBucketItems: 'false'
        }
      }, */
      // crossplot al2O3 vs cuppm - more properties
      xplotOptions: {
        title: 'Simple Proxy Model: Linear fit of Aluminum versus Copper concentration',
        hAxis: {title: 'AL2O3 (percent)'},
        vAxis: {title: 'Copper (parts-per-million)'},
        // legend: 'none',
        height: 600,
        colors: ['#D19C4C'],
        trendlines: { 0: {
          type: 'linear',
          opacity: 0.3,
          showR2: true,
          visibleInLegend: true
        } }    // Draw a trendline for data series 0.
      },
    }
  },
  // access local data
  /* mounted () {
    axios.get("./data/welldata.json")
    .then(response => (this.welldata = response.data)) 
  }, */
  // Learn more about this component at https://www.npmjs.com/package/vue2-google-maps
  methods: {
    async updateCenter(event) {
      // Remove existing markers
      this.markers = [];
      this.markersdata = [];
      this.as = [];
      this.co = [];
      this.ga = [];
      this.li = [];
      this.mn = [];
      this.ag = [];
      this.au = [];
      this.al2o3hist = [];
      this.cuppmhist = [];
      this.al2o3_cuppm_xplot = [];
      // this.markersfmt = [];
      //this.markerschart = [];
      this.message = '';
      this.total = '';

      this.center = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      /*
      min latitude set to the east of southern tip of Florida (25.12, -66.95)
      min longitude set to Victoria Island, near Vancouvr BC (49.38, -124.77) */
      /* if (!(inRange(this.center.lat, 25.12, 49.38)           N-S
        && inRange(this.center.lng, -124.77, -66.95)))          E-W
        all US NE corner Alaska (71.67, -169.54) to SW tip Florida (25.12, -66.95)
        https://gist.github.com/jakebathman/719e8416191ba14bb6e700fc2d5fccc5 */
      if (!(inRange(this.center.lat, 24.3, 71.6)
        && inRange(this.center.lng, -169.98, -78.9))) {
          this.message = 'Location falls outside North America! Please try again.'
        } else { 

        try {
          "use strict";
          const results = await axios({
            method: 'post',
            url: 'https://3c3gcplmke.execute-api.us-east-1.amazonaws.com/prod/',
            data: {
              lat: this.center.lat,
              lng: this.center.lng
            }
          })
          const markerdata = [
            { type: "string", label: "USGS ID", id: "lab_id" },
            { type: "number", label: "Aluminum AL2O3 %", id: "AL2O3_XRF" },
            { type: "number", label: "Arsenic ppm", id: "ASPPM_AES" },
            { type: "number", label: "Cobalt ppm", id: "COPPM_AES" },
            { type: "number", label: "Copper ppm", id: "CUPPM_AES" },
            { type: "number", label: "Gallium ppm", id: "GAPPM_AES" },
            { type: "number", label: "Lithium ppm", id: "LIPPM_AES" },
            { type: "number", label: "Manganese ppm", id: "MNPPM_AES" },
            { type: "number", label: "Manganese MNO %", id: "MNO_XRF" },
            { type: "number", label: "Gold ppm", id: "AUPPM_AES" },
            { type: "number", label: "Silver ppm", id: "AGPPM_AES" },
            { type: "number", label: "Phosphorus P2O5 %", id: "P2O5_XRF" },   // top elements live > 10%
            { type: "number", label: "Potassium K2O %", id: "K2O_XRF" },
            { type: "number", label: "Calcium CAO %", id: "CAO_XRF" },
            { type: "number", label: "Titanium TIO2 %", id: "TIO2_XRF" },
            { type: "number", label: "Silica %", id: "SIO2_XRF" },
            { type: "number", label: "Sodium NA2O %", id: "NA2O_XRF" },
            { type: "number", label: "Magnesium MGO %", id: "MGO_XRF" },
            { type: "number", label: "Iron FE2O3 %", id: "FETO3_XRF" },
            { type: "number", label: "Yttrium ppm", id: "YPPM_AES" },
            { type: "number", label: "Nickel ppm", id: "NIPPM_AES" },
            { type: "number", label: "Chromium ppm", id: "CRPPM_AES" },
            { type: "number", label: "Vanadium ppm", id: "VPPM_AES" },
            { type: "number", label: "Lanthanum ppm", id: "LAPPM_AES" },
            { type: "number", label: "Lead ppm", id: "PBPPM_AES" },
            { type: "number", label: "Zinc ppm", id: "ZNPPM_AES" },
            { type: "number", label: "Barium ppm", id: "BAPPM_AES" },
            { type: "number", label: "Niobium ppm", id: "NBPPM_AES" },
            { type: "number", label: "Beryllium ppm", id: "BEPPM_AES" },
            { type: "number", label: "Cerium ppm", id: "CEPPM_AES" },
            { type: "number", label: "Cadmium ppm", id: "CDPPM_AES" },
            { type: "number", label: "Neodymium ppm", id: "NDPPM_AES" },
            { type: "number", label: "Ytterbium ppm", id: "YBPPM_AES" },
            { type: "number", label: "Bismuth ppm", id: "BIPPM_AES" },
            { type: "number", label: "Europium ppm", id: "EUPPM_AES" },
            { type: "number", label: "Holmium ppm", id: "HOPPM_AES" },
            { type: "number", label: "Scandium ppm", id: "SCPPM_AES" },
            { type: "number", label: "Uranium ppm", id: "UPPM_AES" },
            { type: "number", label: "Tin ppm", id: "SNPPM_AES" },
            { type: "number", label: "Tantalum ppm", id: "TAPPM_AES" },
            { type: "number", label: "Zirconium ppm", id: "ZRPPM_XRF" }
            ];
            // console.log(markerdata);
            this.markersdata.push(markerdata);
            this.as.push(['LAB ID', 'Arsenic']);
            this.co.push(['LAB ID', 'Cobalt']);
            this.ga.push(['LAB ID', 'Gallium']);
            this.li.push(['LAB ID', 'Lithium']);
            this.mn.push(['LAB ID', 'Manganese']);
            this.ag.push(['LAB ID', 'Silver']);
            this.au.push(['LAB ID', 'Gold']);
            this.al2o3hist.push(['LAB ID', 'AL2O3']);
            this.cuppmhist.push(['LAB ID', 'Copper']);
            this.al2o3_cuppm_xplot.push(['AL2O3', 'Copper'])

          results.data.map((location) => {
            const point = JSON.parse(location.geoJson.S)
            const marker = {
              lat: point.coordinates[1],
              lng: point.coordinates[0],
              lab_id: location.lab_id.S,
              al2o3: location.AL2O3_XRF.N,
              cu: location.CUPPM_AES.N,
              p2o5: location.P2O5_XRF.N,
              k2o: location.K2O_XRF.N,
              cao: location.CAO_XRF.N,
              tio2: location.TIO2_XRF.N,
              sio2: location.SIO2_XRF.N,
              na2o: location.NA2O_XRF.N,
              mgo: location.MGO_XRF.N,
              mno: location.MNO_XRF.N,
              fe2o3: location.FETO3_XRF.N,
              y: location.YPPM_AES.N,
              ni: location.NIPPM_AES.N,
              cr: location.CRPPM_AES.N,
              co: location.COPPM_AES.N,
              v: location.VPPM_AES.N,
              la: location.LAPPM_AES.N,
              pb: location.PBPPM_AES.N,
              zn: location.ZNPPM_AES.N,
              mn: location.MNPPM_AES.N,
              ba: location.BAPPM_AES.N,
              nb: location.NBPPM_AES.N,
              be: location.BEPPM_AES.N,
              ce: location.CEPPM_AES.N,
              cd: location.CDPPM_AES.N,
              nd: location.NDPPM_AES.N,
              yb: location.YBPPM_AES.N,
              bi: location.BIPPM_AES.N,
              eu: location.EUPPM_AES.N,
              ho: location.HOPPM_AES.N,
              ag: location.AGPPM_AES.N,
              sc: location.SCPPM_AES.N,
              li: location.LIPPM_AES.N,
              ga: location.GAPPM_AES.N,
              as: location.ASPPM_AES.N,
              u:  location.UPPM_AES.N,
              sn: location.SNPPM_AES.N,
              au: location.AUPPM_AES.N,
              ta: location.TAPPM_AES.N,
              zr: location.ZRPPM_XRF.N
            }
            this.markers.push({ position: marker });

            /* const markerdata = [
              lab_id:
              al2o3:
              cu:
              p2o5:
              k2o: 
              cao:
              tio2:
              sio2:
              na2o:
              mgo:
              mno:
              fe2o3:
              y: 
              ni: 
              cr: 
              co: 
              v: 
              la: 
              pb: 
              zn: 
              mn: 
              ba:
              nb: 
              be: 
              ce: 
              cd: 
              nd:
              yb: 
              bi: 
              eu: 
              ho: 
              ag: 
              sc: 
              li: 
              ga: 
              as: 
              u: 
              sn: 
              au: 
              ta: 
              zr:
            ] */
            //
            // console.log(marker);
            // 41 elements
            this.markersdata.push([marker.lab_id, marker.al2o3, marker.as, marker.co,
            marker.cu, marker.ga, marker.li, marker.mn, marker.mno, marker.au, marker.ag,
            marker.p2o5, marker.k2o, marker.cao, marker.tio2, marker.sio2,
            marker.na2o, marker.mgo, marker.fe2o3, marker.y,
            marker.ni, marker.cr, marker.v, marker.la, marker.pb,
            marker.zn,  marker.ba, marker.nb, marker.be, marker.ce, 
            marker.cd, marker.nd, marker.yb, marker.bi, marker.eu, marker.ho, 
            marker.sc, marker.u, marker.sn,  marker.ta, marker.zr]);
            /*
            create tracking array for each mineral / pair as needed. Bypass all-zero rows.
            */
            const someIsNotZero = this.markersdata.some(item => item !== 0);
            // console.log(someIsNotZero);
            if (someIsNotZero) {
              if (marker.co > 0.0) this.co.push([marker.lab_id, parseFloat(marker.co)]);
              if (marker.li > 0.0) this.li.push([marker.lab_id, parseFloat(marker.li)]);
              if (marker.mn > 0.0) this.mn.push([marker.lab_id, parseFloat(marker.mn)]);
              if (marker.ga > 0.0) this.ga.push([marker.lab_id, parseFloat(marker.ga)]);
              if (marker.as > 0.0) this.as.push([marker.lab_id, parseFloat(marker.as)]);
              if (marker.ag > 0.0) this.ag.push([marker.lab_id, parseFloat(marker.ag)]);
              if (marker.au > 0.0) this.au.push([marker.lab_id, parseFloat(marker.au)]);
              if (marker.al2o3 > 0.0) this.al2o3hist.push([marker.lab_id, parseFloat(marker.al2o3)]);
              if (marker.cu > 0.0) this.cuppmhist.push([marker.lab_id, parseFloat(marker.cu)]); 
              if (marker.al2o3 > 0.0 && marker.cu > 0.0)
                  this.al2o3_cuppm_xplot.push([parseFloat(marker.al2o3), parseFloat(marker.cu)]); 
            }
            //
          })
          //
          // console.log(this.al2o3hist.length, this.cuppmhist.length);
          // if no markers send message
          if (this.markers.length == 0) {
            this.message = 'No samples clustered around this location. Try another spot.'
          } else {
            this.total = 'Way to go. Spot ' + this.markers.length +  ' minerals locations.'
          }
        }
        catch (err) {
        // eslint-disable-next-line
        console.err('Error: ', err)
        }
      }
    }
  }  
}

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>